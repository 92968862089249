import React from "react";
import "./about.css";

const About = () => {
  return (
    <div id="about">
      <div className="about-container">
        <div className="about-text-container">
          <h1 className="about-text-heading">About SourceSprint </h1>
          <hr className="about-hr"/>
          <div className="about-text-subtext">
            <p className="about-text-subtext1">
            SourceSprint is a one-of-a-kind Contest organized by IEEE-VIT.
Get an in-depth understanding on Git and GitHub with an interactive hands-on session.
Following the workshop, get ready to be inspired by some of the most amazing speakers from the world of open sourcing.
Merge PRs and solve issues of projects in various domains in the final phase of the event to win exciting prizes.


            </p>
          </div>
        </div>
        {/* <div className="about-bg">
          <img src={aboutbg} className="about-bg"></img>
        </div> */}
        <div></div>
      </div>
    </div>
  );
};

export default About;