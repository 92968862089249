import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./navbar.styles.css";
import { Button } from "react-scroll";

const Navbar = () => {
  const [scrolling,setscrolling]=useState(false);
  const handlescroll=()=>{
    if(window.scrollY>700){
      setscrolling(true);
    }
    else{
      setscrolling(false);
    }
  }
  useEffect(()=>{
    window.addEventListener('scroll',handlescroll);
    return ()=>{
      window.removeEventListener('scroll',handlescroll)
    }
  },[]);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
  return (
    <nav className={`navbar ${scrolling?'scrolling':''}`}>
      <div className="nav-links">
        <div>
          <a href="#home">Home</a>
        </div>
        <div>
          <a href="#sponsor">Sponsor</a>
        </div>
        <div>
          <a href="#about">About</a>
        </div>
        <div>
          <a href="#timeline">Timeline</a>
        </div>
        <div>
          <a href="#leaderboard">Leaderboard</a>
        </div>
        <div>
          <a href="#faqs">FAQs</a>
        </div>
        {/* {scrolling && <div > <button className="navbar-register-btn"> Register</button> </div>} */}
      </div>
      {/* <Link to="/register">
        <button className="nav-btn" type="button">
          REGISTER
        </button>
      </Link> */}
    </nav>
  );
};

export default Navbar;
