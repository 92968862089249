//FAQ page's content goes from here
const INST_LIST = [{
    instruction: "Please follow the contributing guidlines as mentioned on the contributing.md of each repository."
  },
  {
    instruction: "Choose whichever issues you want to solve from the 4 repositories from IEEE-VIT GitHub."
  },
  {
    instruction: "Solve and contribute as much as you can in the stipulated time period."
  },
  {
    instruction: "For every merged pull request, 10 points will be awarded."
  },
  {
    instruction: "The leaderboard will be frozen exactly at 11:59 pm on 23rd September'23."
  },
  {
    instruction: "The one on the top of the leaderboard will given exciting goodies."
  },
  {
    instruction: "Please be respectful with the repository maintainers and the fellow contributors."
  }
];

export default INST_LIST;