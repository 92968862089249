import React, { useState } from "react";
import "./home.css";
import axios from "axios";
import SSHome from "../../assets/svg/home-svg/ss-home.svg";
import SSLogo from "../../assets/svg/home-svg/ss-logo.png";
import ieeelogo from "../../assets/svg/home-svg/ieee-logo.svg";
import githublogo from "../../assets/svg/home-svg/github-logo.svg"
const ieee_link = "https://ieeevit.org/";
const HactoberFest_link = "https://hacktoberfest.digitalocean.com/";
const FOSSFiesta_link = "https://sourcesprint.ieeevit.org/";
const enroll_link = process.env.REACT_APP_GITHUB_URL;

const Home = () => {
  console.log(enroll_link);
  //Github Auth
  const handleonClick = () => {
    window.location.replace(enroll_link);
    // axios.get(`${enroll_link}`, {}).then(function (response) {
    //   console.log(response);
    //   if (response.lastErrorObject.updatedExisting === true) {
    //     console.log(response);
    //   } else {
    //     console.log("failed", response);
    //   }
    // }).catch((err) => {
    //   console.log(err);
    // });
  };
  let isLoggedIn = localStorage.getItem("isLoggedIn");

  const handleLogOut = () => {
    localStorage.setItem("isLoggedIn", false);
    window.location.reload();
  };

  return (
    <div id="home" className="home-container">
      <img
        src={SSLogo}
        alt="SourceSprint Logo"
        className="sourcesprint-logo"
      ></img>
      {isLoggedIn === "true" ? (
        <button className="home-register-btn" onClick={handleLogOut}>
          Log Out
        </button>
      ) : (
        <button className="home-register-btn" onClick={handleonClick}>
          <img src={githublogo} alt="Github" className="github-icon" /> Register
        </button>
      )}

      <img src={ieeelogo} className="home-ieee-logo" alt="IEEE Logo"></img>
    </div>
  );
};

export default Home;
