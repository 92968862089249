import React from 'react'
import './timeline.css'

function Timeline() {
  return (
    <div className='timeline-container' id='timeline'>
      <h1>TIMELINE</h1>
      <div className="timeline-blocks">
        <div className="timeline-block"><p><b>8 AM - 9 AM :</b> Intro to Open Source</p></div>  
        <div className="timeline-block"><p><b>9 AM - 11 AM :</b> Hands On Session</p></div>
        <div className="timeline-block"><p><b>11 AM - 12:30 PM:</b> Speaker Session</p></div>
        <div className="timeline-block"><p><b>3:30 PM - 7 PM :</b> SourceSprint</p></div>
      </div>


    </div>
  )
}

export default Timeline
